import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Petit bolet amb un capell d’1 a 5 cm de diàmetre, en forma d’ou que després torna acampanat i finalment pla. El centre és de color groc sofre o vermell d’ou, viscós quan hi ha humitat. Les làmines de davall són atapeïdes, blanques que tornen color rovell per la caiguda de les espores. El peu de 5-10 cm d’altura per 2-5 mm de diàmetre és blanc o un poc groguenc. Les espores són de color marró rogenc en massa, el·líptiques, de 12-14 x 7-9 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      